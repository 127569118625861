
import { defineComponent, ref, onMounted } from 'vue';
import { ElConfigProvider } from 'element-plus';
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import useEmitter from '@/composables/Emmiter'
import ApiService from '@/services/ApiService';
import FilterBase from '@/layout/header/partials/filters/FilterBase.vue';
import distribuidorConfig from "@/core/config/DistribuidorConfig";
import { useStore } from 'vuex';
import { Actions } from '@/store/enums/StoreEnums';


export default defineComponent({
  name: "filtro-grupos",
  components: {
    FilterBase,
    ElConfigProvider,
  },
  setup(props, { emit }) {

    const disabledClick = ref(false);
    const emitter = useEmitter();
    const activeModal = ref(false);
    const closeModal = ref(false);
    const label = ref("faça filtros e aprimore sua busca");
    const showClose = ref(false)
    const optionsForncedor: any = ref([]);
    const distribuidor = distribuidorConfig[0];
    const codFornecedor: any = ref(1)
    const optionsConcessionaria = ref()
    const codConcessionariaSelected = ref(localStorage.getItem("filtroConcessionaria"))
    const codConcessionariaSelectedLocalStorage = ref(localStorage.getItem("filtroConcessionaria"))
    const store = useStore();


    onMounted(() => {
      if (!codConcessionariaSelected.value) {
        activeModal.value = true
      }
    })

    const loading: any = ref({
      concessionaria: false
    });
    const showGoToBack = ref(false);

    optionsForncedor.value = [
      {
        text: `${distribuidor.distribuidor}`,
        value: 1
      },
      {
        text: "Outros",
        value: 2
      },
      {
        text: "Originais",
        value: 3
      },
      {
        text: `Todos Produtos`,
        value: 4
      },
    ]

    async function emiteMudanca() {

      showGoToBack.value = false;
      await ApiService.valideTokenTime();

      emitter.emit("filtrar-codFornecedor", {
        fornecedor: codFornecedor.value,
      });


      if (codConcessionariaSelected.value) {
        closeModal.value = !closeModal.value;
      }
    }

    return {
      activeModal,
      closeModal,
      showClose,
      loading,
      ptBr,
      emitter,
      emiteMudanca,
      optionsForncedor,
      showGoToBack,
      label,
      disabledClick,
      codFornecedor,
      optionsConcessionaria,
      codConcessionariaSelected
    }
  }
});

